import React from "react";
import theme from "theme";
import { Theme, Icon, Text, Box, Link, Image, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { MdLocationOn, MdEmail, MdPhone } from "react-icons/md";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"contacts"} />
		<Helmet>
			<title>
				Ласкаво просимо до MetroVille
			</title>
			<meta name={"description"} content={"Ваші ворота в міське та приміське життя – MetroVille, де кожна нерухомість розповідає свою історію"} />
			<meta property={"og:title"} content={"Ласкаво просимо до MetroVille"} />
			<meta property={"og:description"} content={"Ваші ворота в міське та приміське життя – MetroVille, де кожна нерухомість розповідає свою історію"} />
			<meta property={"og:image"} content={"https://images.unsplash.com/photo-1534889156217-d643df14f14a?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"} />
			<link rel={"shortcut icon"} href={"https://images.unsplash.com/photo-1534889156217-d643df14f14a?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://images.unsplash.com/photo-1534889156217-d643df14f14a?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://images.unsplash.com/photo-1534889156217-d643df14f14a?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://images.unsplash.com/photo-1534889156217-d643df14f14a?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"} />
			<link rel={"apple-touch-startup-image"} href={"https://images.unsplash.com/photo-1534889156217-d643df14f14a?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"} />
			<meta name={"msapplication-TileImage"} content={"https://images.unsplash.com/photo-1534889156217-d643df14f14a?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"} />
		</Helmet>
		<Components.Header />
		<Section
			color="--light"
			padding="100px 0"
			sm-padding="40px 0"
			position="relative"
			background="linear-gradient(0deg,rgba(25, 30, 34, 0.8) 0%,rgba(25, 30, 34, 0.8) 100%),--color-darkL2 url(https://uploads.quarkly.io/65c4dc341d712a00232fcf5d/images/3-3.jpg?v=2024-02-09T09:45:45.536Z)"
			quarkly-title="Form-1"
		>
			<Box margin="-16px -16px -16px -16px" display="flex" flex-wrap="wrap">
				<Box padding="16px 16px 16px 16px" width="50%" md-width="100%">
					<Box>
						<Box
							sm-padding="64px 0 0 0"
							margin="32px 0 0 0"
							max-width="360px"
							position="relative"
							padding="0 0 0 64px"
						>
							<Icon
								size="48px"
								top="0"
								left="0"
								category="md"
								icon={MdLocationOn}
								position="absolute"
							/>
							<Text as="h4" margin="6px 0" font="--base">
								Наша адреса
							</Text>
							<Text as="p" margin="6px 0" font="--headline3">
								Kulparkivska St, 93, Lviv, Lviv Oblast, 79000
							</Text>
						</Box>
						<Box
							position="relative"
							padding="0 0 0 64px"
							sm-padding="64px 0 0 0"
							margin="64px 0 0 0"
							max-width="360px"
						>
							<Icon
								top="0"
								left="0"
								category="md"
								icon={MdEmail}
								position="absolute"
								size="48px"
							/>
							<Text font="--base" as="h4" margin="6px 0">
								Наша пошта
							</Text>
							<Text as="p" margin="6px 0" font="--headline3">
								<Link href="mailto:hello@luminesto.com" text-decoration="none" hover-text-decoration="underline" color="--light">
									hello@luminesto.com
								</Link>
							</Text>
						</Box>
						<Box padding="0 0 0 64px" margin="64px 0 0 0" max-width="360px" position="relative">
							<Icon
								left="0"
								category="md"
								icon={MdPhone}
								position="absolute"
								size="48px"
								top="0"
							/>
							<Text font="--base" as="h4" margin="6px 0">
								Номер телефону
							</Text>
							<Text as="p" margin="6px 0" font="--headline3">
								0969519103
							</Text>
						</Box>
					</Box>
				</Box>
				<Box width="50%" padding="8px 8px 8px 8px" md-width="100%">
					<Image
						src="https://uploads.quarkly.io/65c4dc341d712a00232fcf5d/images/1-3.jpg?v=2024-02-09T09:45:45.501Z"
						display="block"
						height="100%"
						width="100%"
						max-width="100%"
						srcSet="https://smartuploads.quarkly.io/65c4dc341d712a00232fcf5d/images/1-3.jpg?v=2024-02-09T09%3A45%3A45.501Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65c4dc341d712a00232fcf5d/images/1-3.jpg?v=2024-02-09T09%3A45%3A45.501Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65c4dc341d712a00232fcf5d/images/1-3.jpg?v=2024-02-09T09%3A45%3A45.501Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65c4dc341d712a00232fcf5d/images/1-3.jpg?v=2024-02-09T09%3A45%3A45.501Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65c4dc341d712a00232fcf5d/images/1-3.jpg?v=2024-02-09T09%3A45%3A45.501Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65c4dc341d712a00232fcf5d/images/1-3.jpg?v=2024-02-09T09%3A45%3A45.501Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65c4dc341d712a00232fcf5d/images/1-3.jpg?v=2024-02-09T09%3A45%3A45.501Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Box />
				</Box>
			</Box>
		</Section>
		<Components.Footer>
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="link2" />
		</Components.Footer>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65c4dc341d712a00232fcf5b"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});